import { Modal as AntModal } from 'antd';
import Typography from "../../components/Typography";
import Image from "next/image";
import InputButtonUnder from "../../components/Form/InputButtonUnder";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";
import dynamic from 'next/dynamic';

// Client-side only Modal
const ClientOnlyModal = dynamic(
  () => import('antd').then(mod => mod.Modal),
  { ssr: false }
);

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(200, 200, 200, 0.3);
  border: none;
  font-size: 2.5rem;
  cursor: pointer;
  color: black;
  z-index: 999;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  line-height: 0.65;
  padding: 0 0 4px 0;
  transition: background-color 0.2s ease;

  &:hover {
    background: rgba(200, 200, 200, 0.5);
  }
  
  @media (max-width: 768px) {
    background: rgba(70, 70, 70, 0.8);
    color: white;
    line-height: 0.65;
    padding: 0 0 7px 0;
    
    &:hover {
      background: rgba(70, 70, 70, 0.9);
    }
  }
  
  @media (min-width: 769px) {
    top: 10px;
    right: 10px;
    font-size: 2.5rem;
  }
`;

const HighlightText = styled.span`
  display: inline-block;
  color: #762738;
`;

const SuperScript = styled.sup`
  font-size: 0.6em;
  position: relative;
  top: -0.5em;
  color: #762738;
`;

const DisclaimerText = styled.div`
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 1em;
`;

const DisclaimerText2 = styled.div`
  width: calc(100% - 80px);
  margin: 0 20px;
  text-align: center;
  color: ${props => props.isSubmitted ? '#fff' : '#000'};
  font-size: 9px;
  padding: ${props => props.isSubmitted ? '2rem 1rem' : '2rem 1rem'};
  background-color: rgba(192,192,192, 0);
  margin-top: auto;

  @media (max-width: 768px) {
    width: calc(100% - 80px);
    margin: 0 40px;
    padding: ${props => props.isSubmitted ? '2rem 0' : '2rem 0'};
  }
`;

const ModalContent = styled.div`
  box-sizing: border-box;
  width: 800px;
  height: 593px;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  z-index: 1;
  background: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: min-content;
    overflow-y: visible;
    margin: 0;
  }
`;

const Column = styled.div`
  flex: 1;
  position: relative;
`;

const LeftColumn = styled(Column)`
  background-image: url('/images/home_page/closet-shoe-storage.jpg');
  background-size: cover;
  background-position: left center;
  min-height: 593px;
  border-right: 6px solid #762738;

  @media (max-width: 768px) {
    order: -1;
    min-height: 25vh;
    height: 25vh;
    max-height: 25vh;
    margin: 0 -20px;
    width: calc(100% + 40px);
    border-right: none;
    background-position: center;
    background-size: 100% auto;
  }
`;

const RightColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  background-color: ${props => props.isSubmitted ? '#762738' : '#ffffff'};
  overflow-y: visible;
  margin: 0;
  width: 100%;
  transition: background-color 0.3s ease;

  @media (max-width: 768px) {
    padding: 0;
    min-height: min-content;
  }
`;

const FormContent = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  min-height: 450px;

  @media (min-width: 769px) {
    padding: 0 0;
  }

  @media (max-width: 768px) {
    padding: 1rem 40px;
    min-height: auto;
  }
`;

const TextInner = styled.div`
  padding: 0;
  margin-bottom: 5px;
`;

const SuccessTextContent = styled.div`
  padding: 20px;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 60px;

  @media (max-width: 768px) {
    position: static;
    min-height: auto;
    padding: 40px 0 60px;
    margin: 0;
    width: 100%;
  }
`;

const ModalWrapper = styled.div`
  .ant-modal {
    width: 100% !important;
    max-width: 800px;
    margin: 0 auto;
  }

  .ant-modal-content {
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.45);
  }
`;

const ModalBody = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
`;

const ImageContainer = styled.div`
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  
  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 5px;
  }
`;

const UnwrapText = styled(Typography)`
  font-size: 3rem;
  @media (max-width: 768px) {
    font-size: 2rem !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
`;

const OfferText = styled(Typography)`
  font-size: 4.25rem;
  @media (max-width: 768px) {
    font-size: 4rem !important;
    margin-top: 0 !important;
    margin-bottom: 5px !important;
  }
`;

const MakeoverText = styled(Typography)`
  font-size: 1.2rem;
  @media (max-width: 768px) {
    font-size: 1.08rem !important;
    margin-top: 0 !important;
    margin-bottom: 10px !important;
  }
`;

const StyledImageWrapper = styled.div`
  position: relative;
  width: 326px;
  height: 107px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 326px;
    height: 107px;
  }
`;

export default function ModalHoliday({ open, onRequestClose }) {
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [cookie, setCookie] = useCookies(["holiday_popup"]);
  const [isMounted, setIsMounted] = useState(false);
  const previousFocus = useRef(null);

  // Store the previously focused element
  useEffect(() => {
    if (open) {
      previousFocus.current = document.activeElement;
    }
  }, [open]);

  // Handle cleanup and focus restoration
  useEffect(() => {
    return () => {
      if (previousFocus.current && document.body.contains(previousFocus.current)) {
        previousFocus.current.focus();
      } else {
        document.body.focus();
      }
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    const fetchNearestFranchise = async () => {
      try {
        const response = await fetch('/api/location');
        const data = await response.json();
        setCity(data.closestShowroomToUser.properties.city);
      } catch (err) {
        console.error("Failed to fetch nearest franchise data", err);
      }
    };

    fetchNearestFranchise();
  }, []);

  const persistPopupData = () => {
    setCookie("holiday_popup", dayjs().format(), {
      path: "/",
      maxAge: 24 * 60 * 60,
      sameSite: true,
    });
  };

  const handleClose = () => {
    document.body.style.overflow = '';
    persistPopupData();
    
    requestAnimationFrame(() => {
      if (previousFocus.current && document.body.contains(previousFocus.current)) {
        previousFocus.current.focus();
      }
      setIsSubmitted(false);
      onRequestClose();
    });
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/contact/coupon', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, city }),
      });
      const result = await response.json();
      
      if (response.ok) {
        setEmail("");
        setIsSubmitted(true);
      } else {
        setError(result.message || 'Something went wrong');
      }
    } catch (err) {
      setError("Unable to submit");
    } finally {
      setLoading(false);
    }
  };

  if (!isMounted) return null;
  
  return (
    <ClientOnlyModal
      open={open}
      onCancel={handleClose}
      width={800}
      centered
      destroyOnClose
      maskClosable
      mask
      keyboard
      footer={null}
      closable={false}
      style={{
        top: 0,
        paddingBottom: 0
      }}
      bodyStyle={{
        padding: 0,
        overflow: 'hidden',
        backgroundColor: 'white',
        borderRadius: '4px'
      }}
      getContainer={document.body}
      wrapClassName="holiday-modal-wrap"
      zIndex={1050}
    >
      <ModalContent>
        <CloseButton onClick={handleClose}>&times;</CloseButton>
        <LeftColumn />
        <RightColumn isSubmitted={isSubmitted}>
          {isSubmitted ? (
            <SuccessTextContent>
              <Typography
                textAlign="center"
                color="#fff"
                fontSize="2.5rem"
                fontWeight="bold"
                fontType="header"
                margin={[0, 0, 20, 0]}
              >
                Thank you!
              </Typography>
              <Typography
                textAlign="center"
                color="#fff"
                fontSize="1.5rem"
                margin={[0, 0, 20, 0]}
              >
                Your coupon is on its way!
              </Typography>
              <Typography
                textAlign="center"
                color="#fff"
                fontSize="1.2rem"
                margin={[0, 0, 20, 0]}
              >
                Please check your email for your $500 savings coupon.
              </Typography>
            </SuccessTextContent>
          ) : (
            <FormContent>
              <TextInner>
                <ImageContainer>
                  <StyledImageWrapper>
                    <Image
                      src="/images/logo/logo-square.PNG"
                      alt="Sales Logo"
                      width={306}
                      height={120}
                      priority
                      style={{ 
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain'
                      }}
                    />
                  </StyledImageWrapper>
                </ImageContainer>
                <UnwrapText
                  textAlign="center"
                  fontFamily="Roboto Light"
                  color="#000"
                  fontType="headerVariant"
                  fontWeight="bold"
                  margin={[10, 0, 5, 0]}
                  lineHeight="1"
                  style={{ fontStyle: 'italic' }}
                >
                  Enjoy
                </UnwrapText>
                <OfferText
                  textAlign="center"
                  fontFamily="Lora"
                  color="#fff"
                  fontType="headerVariant"
                  fontWeight="bold"
                  margin={[0, 0, 5, 0]}
                  lineHeight="1"
                >
                  <HighlightText><SuperScript>$</SuperScript>500 Off</HighlightText>
                </OfferText>
                <MakeoverText
                  textAlign="center"
                  fontFamily="Roboto Light"
                  color="#000"
                  fontType="headerVariant"
                  fontWeight="bold"
                  margin={[0, 0, 20, 0]}
                  lineHeight="1"
                  style={{ fontStyle: 'italic' }}
                >
                  Your Organization
                  Makeover
                </MakeoverText>
              </TextInner>
              
              <InputButtonUnder
                inputProps={{
                  height: "50px",
                  placeholder: "E-Mail Address",
                  fontWeight: "normal",
                  disabled: loading,
                  onChange: (e) => setEmail(e.target.value),
                  style: { borderRight: '1px solid black' }
                }}
                buttonProps={{
                  height: "50px",
                  loading: loading,
                  onClick: handleFormSubmit,
                }}
                text="CLAIM MY $500"
              />
              
              {error && (
                <Typography
                  textAlign="top"
                  color="negative"
                  margin={[0, 0, 10, 0]}
                  fontSize="9px"
                >
                  {error}
                </Typography>
              )}
            </FormContent>
          )}
          <DisclaimerText2 isSubmitted={isSubmitted}>
            * Consultation must be scheduled by March 31, 2025. Minimum job size $5,000. Not available in all areas. Only applicable to new purchases. Customer must present email to designer upon first consultation. Cannot be combined with any other offer. Limited to 1 coupon per household.
          </DisclaimerText2>
        </RightColumn>
      </ModalContent>
    </ClientOnlyModal>
  );
} 