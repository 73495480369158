import styled, { css } from "styled-components";
import PButton from "../../PButton";
import PInput from "../../PInput";

export const InputWithButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled(PInput)`
  ${({ theme }) => css`
    border-color: ${theme.colors.borderColor1};
  `}
  border-width: 1px;
  width:100%;
  border-right: 0px;
  box-shadow: none;
  margin:0 10px 0 0px;;
  ~ div {
    width: 100%;
    position: absolute;
    justify-content: flex-start;
    > span {
      width: 16px !important;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
`;

export const Button = styled(PButton)`
  border-radius: 0px;
  ${({ theme }) => css`
    background-color: ${theme.colors.darkGray2};
  `}
margin:10px 10px 1rem 0px; /* Add margin to separate the input and button */
    width:100%;
`;
