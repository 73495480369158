import { withTheme } from "styled-components";
import {
  InputWithButtonContainer,
  InputContainer,
  Input,
  Button,
} from "./styled";

function InputButtonUnder({ theme, inputProps, buttonProps, text }) {
  return (
    <InputWithButtonContainer>
      <InputContainer>
        <Input {...(inputProps || {})} />
        <Button
          aria-label={"submit form"}
          ptype="primary"
          pname="Submit"
          shadow="yes"
          height="30px"
          pright="40px"
          pleft="40px"
          hoverBgColor={theme.colors.prune}
          onClick={() => {}}
          {...(buttonProps || {})}
        >
          {text}
        </Button>
      </InputContainer>
    </InputWithButtonContainer>
  );
}

export default withTheme(InputButtonUnder);
